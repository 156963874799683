import { useState, useEffect, useMemo } from "react";
import { AxiosResponse } from "axios";
import { DefaultButton, IComboBoxOption } from "@fluentui/react";
import { useApi, useHttp } from "hooks";
import { CommandbarCommands } from "constants/enums";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicInput } from "components/common/BasicInput";
import { BasicDropdown } from "components/common/BasicDropdown";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { NumberField } from "components/common/NumberField";
import loc from "localization";
import { getNextValueCheckboxWithNull } from "utils/getNextValueCheckboxWithNull";
import styles from "../styles.module.scss";

interface IFeaturesTabProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  firmId?: number;
  deviceId?: number;
  currencyOptions: IComboBoxOption[],
  specPermission?: boolean;
  otkPermission?: boolean;
  onRowDataUpdate?: () => void;
  updateState: () => void;
  setActiveCommand: (command: CommandbarCommands) => void;
  readonly?: boolean;
  setPremilinaryrepairCost: (val: boolean) => void;
  externalUser: boolean;
}

export const FeaturesTab = (props: IFeaturesTabProps) => {
  const { formik, specPermission, currencyOptions } = props;
  const { devicesApi } = useApi();
  const checkReturnHttp = useHttp();
  const updateDeviceHttp = useHttp();
  const [returnIsDisabled, setReturnIsDisabled] = useState<boolean>(true);

  const setPaymentConsent = (value: boolean | null) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
      deviceSetPaymentConsentPayload: {
        platRem: value,
      },
    }))
      .then(() => formik.setFieldValue("platRem", value));
  };

  const cancelInvoice = (value: boolean) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
    }))
      .then(() => {
        formik.setFieldValue("schet", value);
        formik.handleSubmit();
        props.updateState();
      });
  };
  const setRepairCost = (value?: number) => {
    formik.setFieldValue("previousCostRem", formik.values.stoimRem);
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
      deviceSetRepairCostPayload: {
        stoimRem: Number(formik.values.stoimRem),
        valRem: value || formik.values.valRem
      },
    })).then(props.updateState);
  };

  const returnWithoutRepair = (value: boolean) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesReturnWithoutRepairPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
      deviceReturnWithoutRepairPayload: {
        vozvrBezR: value,
      },
    }))
      .then(() => formik.setFieldValue("vozvrBezR", value));
  };

  const checkReturnWithoutRepair = () => {
    checkReturnHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
    }))
      .then((res: AxiosResponse) => setReturnIsDisabled(!res.data.canReturnWithoutRepair));
  };

  useEffect(() => {
    checkReturnWithoutRepair();
  }, []);

  useEffect(() => {
    if (formik.values.warranty && formik.values.firmId !== 6) formik.setFieldValue("stoimRem", 0);
  }, [formik.values.warranty]);

  const isDisabledRepairWithoutCost = useMemo(() =>
    Boolean(!(
      formik?.values?.skl?.substr(1) === "01"
      || formik?.values?.skl?.substr(1) === "02"
      || formik?.values?.skl?.substr(1) === "05"
      || formik?.values?.skl?.substr(1) === "07"
    )), [formik?.values?.skl]);

  return (
    <>
      <BasicModalColumns>
        <BasicModalColumn>
          <b>{loc.labels.control}</b>
          <InfoContainer infoContainerClassName={styles.controlContainer} label={loc.labels["2control"]}>
            <div className="flex-row">
              <BasicCheckbox
                label={loc.labels.assigned}
                checked={formik.values.reControl}
                disabled
              />
              <BasicCheckbox
                label={loc.labels.passed}
                checked={formik.values.reControlC}
                disabled
              />
            </div>
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <div className="flex-row" style={{ paddingTop: "10px" }}>
            <InfoContainer infoContainerLabelClassName={styles.width170} label={loc.labels.repairConfirmation}>
              <BasicCheckbox
                checked={formik.values.repeatedRepairConfirmed}
                disabled
              />

            </InfoContainer>
            <InfoContainer infoContainerLabelClassName={styles.width170} label={loc.labels.replacementToNew}>
              <BasicCheckbox
                checked={formik.values.replacementForNew}
                disabled
              />
            </InfoContainer>
          </div>

        </BasicModalColumn>
      </BasicModalColumns>
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer label={loc.labels.requestClarification}>
            <BasicCheckbox
              checked={formik.values.requestConfirmation}
              disabled
            />
          </InfoContainer>

        </BasicModalColumn>
        <BasicModalColumn>
          <></>
        </BasicModalColumn>
      </BasicModalColumns>
      <BasicModalColumns>

        <BasicModalColumn>
          <BasicModalColumns>
            <BasicModalColumn>
              <InfoContainer label={loc.labels.complaints}>
                <BasicInput
                  multiline
                  name={"complaints"}
                  value={formik.values.complaints}
                  onChange={(value: string) => formik.setFieldValue("complaints", value)}
                  onBlur={formik.handleSubmit}
                  errorMessage={formik.errors.complaints}
                  maxLength={254}
                  disabled={props.readonly}
                />
              </InfoContainer>
              <InfoContainer label={loc.labels.appearance}>
                <BasicInput
                  multiline
                  name={"vneshVid"}
                  value={formik.values.vneshVid}
                  onChange={(value: string) => formik.setFieldValue("vneshVid", value)}
                  onBlur={formik.handleSubmit}
                  maxLength={254}
                  errorMessage={formik.errors.vneshVid}
                  disabled={props.readonly}
                />
              </InfoContainer>
              <DefaultButton
                iconProps={{ iconName: "add", style: { fontSize: 10 } }}
                style={{ position: "relative", top: 30, padding: 0, marginBottom: 10, minWidth: 20, height: 20 }}
                onClick={() => props.setActiveCommand(CommandbarCommands.Equipment)}
                disabled={props.readonly}
              />
              <InfoContainer label={loc.labels.packageOfCompletion}>
                <BasicInput
                  multiline
                  name={"komplekt"}
                  value={formik.values.komplekt}
                  onChange={(value: string) => formik.setFieldValue("komplekt", value)}
                  onBlur={formik.handleSubmit}
                  maxLength={254}
                  errorMessage={formik.errors.komplekt}
                  disabled={props.readonly}
                />
              </InfoContainer>
            </BasicModalColumn>
          </BasicModalColumns>
        </BasicModalColumn>

        <BasicModalColumn>
          <b>{loc.labels.toTheSaleState}</b>
          <BasicModalColumns>
            <BasicModalColumn>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.repair}
                  checked={formik.values.repairToSaleState}
                  onChange={(checked) => {
                    formik.setFieldValue("repairToSaleState", checked);
                    formik.handleSubmit({ repairToSaleState: checked });
                  }}
                  disabled={props.readonly}
                />
              </InfoContainer>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.complete}
                  checked={formik.values.completeToSaleState}
                  onChange={(checked) => {
                    formik.setFieldValue("completeToSaleState", checked);
                    formik.handleSubmit({ completeToSaleState: checked });
                  }}
                  disabled={props.readonly}
                />
              </InfoContainer>
            </BasicModalColumn>
          </BasicModalColumns>
          <b>{loc.labels.paidRepair}</b>
          <BasicModalColumns>
            <BasicModalColumn>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.returnWithoutRepair}
                  checked={formik.values.vozvrBezR}
                  onChange={(val) => {
                    if (val) {
                      cancelInvoice(false);
                      formik.setFieldValue("preliminaryCost", false);
                      props.setPremilinaryrepairCost(false);
                    };
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    returnWithoutRepair(val);
                  }}
                  disabled={returnIsDisabled || props.readonly || formik.values.deviceOnRepairArea}
                />
              </InfoContainer>
            </BasicModalColumn>
            <BasicModalColumn>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.prostaf}
                  checked={formik.values.prostaf}
                  onChange={(checked) => {
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    formik.setFieldValue("prostaf", checked);
                    formik.handleSubmit({ prostaf: checked });
                  }}
                  disabled={props.readonly}
                />
              </InfoContainer>
            </BasicModalColumn>
          </BasicModalColumns>
          <BasicModalColumns>
            <BasicModalColumn>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.agreedToPay}
                  checked={formik.values.platRem}
                  indeterminate={formik.values.platRem === null}
                  onChange={() => {
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    setPaymentConsent(getNextValueCheckboxWithNull(formik.values.platRem));
                  }}
                  disabled={!props.externalUser ? !specPermission || !formik.values.schet || props.readonly || formik.values.warranty : false}
                />
              </InfoContainer>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.labels.sendInvoice}
                  checked={formik.values.schet}
                  onChange={(checked) => {
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    formik.setValues({
                      ...formik.values,
                      schet: checked,
                      platRem: null,
                      valRem: formik.values.konVal,
                      stoimRem: !checked ? "" : formik.values.stoimRem,
                    });
                    !checked && cancelInvoice(checked);
                  }}
                  disabled={!props.externalUser ? props.readonly || formik.values.warranty : false}
                />
              </InfoContainer>
              <InfoContainer label={loc.labels.estCostOfRepair}>
                <NumberField
                  item={formik.values}
                  name="stoimRem"
                  value={formik.values.stoimRem}
                  onChange={(_, field: string, value?: string) => {
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    formik.setValues({
                      ...formik.values,
                      [field]: value,
                      valRem: (Number(value) > 0 && !formik.values.valRem)
                        ? (formik.values.konVal || 3)
                        : formik.values.valRem,
                    });
                  }}
                  onBlur={() => Number(formik.values.stoimRem) ? setRepairCost() : cancelInvoice(false)}
                  errorMessage={formik.errors.stoimRem}
                  disabled={!props.externalUser ? !formik.values.schet || props.readonly || formik.values.warranty || isDisabledRepairWithoutCost : false}
                />
              </InfoContainer>
              <InfoContainer label={loc.columns.currency}>
                <BasicDropdown
                  clearButtonOff
                  itemKey={`${formik.values.valRem}`}
                  options={currencyOptions}
                  disabled={!formik.values.schet || props.readonly || isDisabledRepairWithoutCost}
                  errorMessage={formik.errors.valRem}
                  onChange={(_, option?: IComboBoxOption) => {
                    props.onRowDataUpdate && props.onRowDataUpdate();
                    setRepairCost(Number(option?.key));
                  }}
                />
              </InfoContainer>
              {!formik.values.warranty || props.externalUser ? (
                <InfoContainer label={loc.labels.preliminaryCost}>
                  <BasicCheckbox
                    checked={formik.values.preliminaryCost}
                    onChange={(checked) => {
                      props.onRowDataUpdate && props.onRowDataUpdate();
                      formik.setFieldValue("preliminaryCost", checked);
                      props.setPremilinaryrepairCost(checked);
                    }}
                    disabled={isDisabledRepairWithoutCost}
                  />
                </InfoContainer>
              ) : <></>}
              {!formik.values.warranty || props.externalUser ? (
                <InfoContainer label={loc.labels.repairWithoutCost}>
                  <BasicCheckbox
                    checked={formik.values.repairWithoutCost}
                    onChange={(checked) => {
                      props.onRowDataUpdate && props.onRowDataUpdate();
                      formik.setFieldValue("repairWithoutCost", checked);
                      formik.handleSubmit({ repairWithoutCost: checked });
                    }}
                    disabled={isDisabledRepairWithoutCost || Boolean(formik.values.stoimRem)}
                  />
                </InfoContainer>
              ) : <></>}
            </BasicModalColumn>
          </BasicModalColumns>
        </BasicModalColumn>
      </BasicModalColumns>
    </>

  );
};
