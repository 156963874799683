import { useContext, useEffect, useState } from "react";
import { ScrollablePane, SelectionMode } from "@fluentui/react";
import { useApi, useHttp } from "hooks";
import { DeviceExpiringLicenseDto } from "generated-sources/openapi";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { Table } from "components/common/Table";
import styles from "../ExpiringLicenses/styles.module.scss";
import { getColumns } from "./config";

interface IDevicesWithExpiringLicensesProps {
  firm: number;
  onDismiss: () => void;
}

export const DevicesWithExpiringLicenses = (props: IDevicesWithExpiringLicensesProps) => {
  const devicesWithExpiringLicensesHttp = useHttp<DeviceExpiringLicenseDto[]>({ showErrors: true });
  const { loc, language } = useContext(LanguageContext);
  const { dashboardApi } = useApi();
  const [devices, setDevices] = useState<DeviceExpiringLicenseDto[]>([]);

  const loadData = () => {
    devicesWithExpiringLicensesHttp.request(() =>
      dashboardApi.apiDashboardFirmIdDevicesWithExpiringLicensesPost({
        firmId: props.firm,
      })
    ).then(res => setDevices(res.data));
  };

  useEffect(() => {
    loadData();
  }, []);

  const columns = getColumns(language);
  return (
    <BasicModal
      additionalClassName={styles.expiringLicensesModal}
      isLoading={devicesWithExpiringLicensesHttp.isLoading}
      title={`${loc.labels.devices} ${loc.labels.withExpiringLicense}`}
      errors={devicesWithExpiringLicensesHttp.errorMessages}
      onDismiss={props.onDismiss}
      dismissButtonText={loc.buttons.close}
    >
      <BasicModalContent>
        <div className="scrollable-table-wrapper">
          <ScrollablePane>
            <Table
              columns={columns}
              items={devices}
              selectionMode={SelectionMode.none}
            />
          </ScrollablePane>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};