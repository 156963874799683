import { useEffect, useState, useRef } from "react";
import { debounce, find, isUndefined, map, sortBy } from "lodash-es";
import {
  DetailsList,
  Sticky,
  SelectionMode,
  IColumn,
  ISelection,
  ActionButton,
  IRenderFunction,
  IDetailsHeaderProps,
  FontIcon,
  TooltipHost,
  DetailsListLayoutMode,
  IDetailsRowProps,
  IDetailsColumnFieldProps,
  IDetailsList,
} from "@fluentui/react";
import { CommandbarCommands } from "constants/enums";
import { ICommandBarAction } from "../CommandBar";
import { ScrollableTableWrapper } from "./components";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { getColumnWidth, getColumnsInfo, updateColumnsInfo } from "utils/saveColumnInfo";

interface ITableProps<T> {
  items: unknown[];
  columns: IColumn[];
  compact?: boolean;
  className?: string;
  selection?: ISelection;
  selectionMode?: SelectionMode;
  actions?: ICommandBarAction[];
  columnEditActions?: {
    key: string,
    command: CommandbarCommands,
    disabled: boolean,
  }[],
  onItemInvokedSaveSelection?: boolean;
  selectionIsVisible?: boolean;
  setActiveCommand?: (command: CommandbarCommands) => void;
  onItemInvoked?: (item?: T) => void;
  onRenderItemColumn?: (item?: unknown, index?: number, column?: IColumn) => JSX.Element;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
  tableKey?: string;
  onRenderField?: IRenderFunction<IDetailsColumnFieldProps>;
  isSelectedOnFocus?: boolean;
  doNotReload?: boolean;
}

const Table = <T,>(props: ITableProps<T>) => {
  const detailsListRef: React.Ref<IDetailsList> = useRef(null);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, render) => (
    <Sticky>{render && render(props)}</Sticky>
  );

  const handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
    const draggedItems = columns[draggedIndex]; 
    const newColumns: IColumn[] = [...columns];
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItems);
    setColumns(newColumns);
    if (props.tableKey) {
      updateColumnsInfo(props.tableKey, newColumns);
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRenderItemColumn = (item?: any, _?: number, column?: IColumn) => {
    const fieldContent = column?.fieldName && item[column.fieldName];
    const columnEditAction = find(props.columnEditActions, { key: column?.key });
    const menuItems = props.actions ? props.actions.filter((action: ICommandBarAction) => action.threeDots) : [];
    if (column?.key === "three-dots" && menuItems.length) {
      return (
        <div className="cell-action-btn">
          <ActionButton
            className={styles.actionBtn}
            iconProps={{ iconName: "MoreVertical" }}
            menuProps={{
              items: map(menuItems, (item) => ({
                ...item,
                onClick: () => props.setActiveCommand ? props.setActiveCommand(item.command) : undefined,
                items: item.items && item.items.map(subitem => ({
                  ...subitem,
                  onClick: () => props.setActiveCommand ? props.setActiveCommand(subitem.command) : undefined,
                }))
              })),
            }}
          />
        </div>
      );
    };
    if (columnEditAction && !columnEditAction.disabled) {
      return (
        <TooltipHost hostClassName={styles.editColumn} content={fieldContent}>
          <span className={styles.editColumnContent}>{fieldContent}</span>
          <FontIcon
            iconName="EditSolid12"
            onClick={() => props.setActiveCommand ? props.setActiveCommand(columnEditAction.command) : undefined}
          />
        </TooltipHost>
      );
    };
    return <span>{fieldContent}</span>;
  };

  useEffect(() => {
    if (props.tableKey) {
      const order = getColumnsInfo(props.tableKey);
      if (!order.length) {
        updateColumnsInfo(props.tableKey, props.columns);
      }
      const updatedColumns = props.columns.map(column => ({
        ...column,
        maxWidth: props.tableKey && getColumnWidth(props.tableKey, column.key) || column.maxWidth
      }));
      const orderedColumns = sortBy(updatedColumns, item => order.findIndex(({ key }: { key: string }) => key === item.key));
      setColumns(orderedColumns);
    } else {
      setColumns(props.columns);
    }
  }, [props.columns, props.tableKey]);

  useEffect(() => {
    if(detailsListRef.current && !props.doNotReload) detailsListRef.current.scrollToIndex(0);
  },[props.items[0]]);

  const onColumnResize = debounce((
    column?: IColumn | undefined,
    newWidth?: number | undefined,
  ) => {
    if (column?.key && props.tableKey && newWidth) {
      const updatedColumns = columns.map(el => {
        if (el.key === column?.key) {
          return ({
            ...el,
            maxWidth: newWidth
          });
        } else {
          return el;
        }
      });
      setColumns(updatedColumns);
      updateColumnsInfo(props.tableKey, updatedColumns);
    }    
  }, 100);

  return (
    <DetailsList
      componentRef={detailsListRef}
      className={classNames(
        styles.table,
        props.className,
        props.selectionIsVisible ? styles.visibleSelectionTable : "",
      )}
      compact={isUndefined(props.compact)}
      isHeaderVisible
      items={props.items}
      setKey={`${props.items.length}`}
      columns={columns}
      selection={props.selection}
      selectionMode={props.selectionMode}
      selectionPreservedOnEmptyClick
      onRenderDetailsHeader={onRenderDetailsHeader}
      onRenderItemColumn={onRenderItemColumn}
      isSelectedOnFocus={isUndefined(props.isSelectedOnFocus)}
      onItemInvoked={props.onItemInvoked}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      onRenderRow={props.onRenderRow}
      columnReorderOptions={props.tableKey ? { handleColumnReorder } : undefined}
      onRenderField={props.onRenderField}
      onColumnResize={
        props.tableKey ?
          onColumnResize
          : undefined
      }
    />
  );
};

export { Table, ScrollableTableWrapper };
