import { ScrollableTableWrapper } from "components/common/Table";
import { Loading } from "components/common/LoadingWrapper";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IPageWrapperDetailsProps {
  isLoading: boolean;
  loadingMore?: boolean;
  notificationWrapper?: JSX.Element;
  className?: string;
  noData: boolean;
  children: JSX.Element;
  serviceView?: boolean;
  filterHeight?: number;
}

export const PageWrapperDetails = (
  props: IPageWrapperDetailsProps
) => {
  return (
    <div
      style={props.filterHeight ? { height: `calc(100% - ${props.filterHeight + 50}px) ` } : {}}
      className={classNames(styles.pageWrapperDetails, props.className, { [styles.heigth105]: props.serviceView })}
    >
      <Loading isLoading={props.isLoading}>
        <>
          <ScrollableTableWrapper
            noData={props.noData}
            loadingMore={props.loadingMore}
          >
            {props.children}
          </ScrollableTableWrapper>
          {props.notificationWrapper}
        </>
      </Loading>
    </div>
  );
};
