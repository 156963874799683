import { find } from "lodash-es";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { getSortingColumnHeader } from "components/common/SortingColumnHeader";
import { AuditEventType } from "constants/enums";
import { DeviceAuditBrowseDto, UserShortProfileDto } from "generated-sources/openapi";
import loc from "localization";
import { transformDateTimeToString } from "utils";
import { ISorting } from "hooks/useSorting";


interface IDeviceInfo {
  deviceFirmId: number;
  deviceId: number;
  seK: number;
  nomer: string;
  warranty: boolean;
  vozvrBezR: boolean;
  stoimRem: number;
  platRem: boolean;
};

export const eventTypes = () => [
  {
    key: AuditEventType.WarrantyChanged,
    text: loc.auditEvents.warrantyChanged,
  },
  {
    key: AuditEventType.ReturnWithoutRepairChanged,
    text: loc.auditEvents.returnWithoutRepairChanged,
  },
  {
    key: AuditEventType.RepairCostChanged,
    text: loc.auditEvents.repairCostChanged,
  },
  {
    key: AuditEventType.PaymentConsentChanged,
    text: loc.auditEvents.paymentConsentChanged,
  },
  {
    key: AuditEventType.SeChanged,
    text: loc.auditEvents.seChanged,
  },
  {
    key: AuditEventType.SerialChanged,
    text: loc.auditEvents.serialChanged,
  },
  {
    key: AuditEventType.DeviceAdded,
    text: loc.auditEvents.deviceAdded,
  },
  {
    key: AuditEventType.DeviceAgreed,
    text: loc.auditEvents.deviceAgreed,
  },
  {
    key: AuditEventType.DeviceDisagreed,
    text: loc.auditEvents.deviceDisagreed,
  },
  {
    key: AuditEventType.RepairAreaChanged,
    text: loc.auditEvents.repairAreaChanged,
  },
  {
    key: AuditEventType.RepairDateChanged,
    text: loc.auditEvents.repairDateChanged,
  },
  {
    key: AuditEventType.SecondaryControlCompletedChanged,
    text: loc.auditEvents.secondaryControlCompletedChanged,
  },
  {
    key: AuditEventType.RepairCostAdded,
    text: loc.auditEvents.repairCostAdded,
  },
  {
    key: AuditEventType.RepairCostRemoved,
    text: loc.auditEvents.repairCostRemoved,
  },
  {
    key: AuditEventType.PreliminaryCostChanged,
    text: loc.auditEvents.preliminaryRepairCostChanged,
  },
  {
    key: AuditEventType.RepeatedRepairConfirmedChanged,
    text: loc.auditEvents.confirmationOfRepeatRepair,
  },
  {
    key: AuditEventType.Packed,
    text: loc.auditEvents.packed,
  },
  {
    key: AuditEventType.PackagingConfirmed,
    text: loc.auditEvents.packagingConfirmed,
  },
  {
    key: AuditEventType.WaitChanged,
    text: loc.auditEvents.deviceWaitChanged,
  },
  {
    key: AuditEventType.CompleteToSalesStateChanged,
    text: loc.auditEvents.deviceCompleteToSalesStateChanged,
  },
  {
    key: AuditEventType.RepairToSalesStateChanged,
    text: loc.auditEvents.deviceRepairToSalesStateChanged,
  },
];

const getEventTypeName = (eventType: string) => find(eventTypes(), ["key", eventType])?.text;

const getDeviceInfo = (info: string) => (JSON.parse(info) as IDeviceInfo);

export const getColumns = (users: UserShortProfileDto[], sorting: ISorting) => [
  {
    key: "eventType",
    fieldName: "eventType",
    name: loc.columns.status,
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => <span>{getEventTypeName(item?.eventType as AuditEventType)}</span>,
  },
  {
    key: "createdBy",
    fieldName: "createdBy",
    name: loc.columns.user,
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => {
      const user = find(users, { id: item.createdBy });
      return `${user?.lastName} ${user?.firstName}`;
    },
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: loc.columns.date,
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRenderHeader: getSortingColumnHeader(sorting),
    onRender: (item: DeviceAuditBrowseDto) => transformDateTimeToString(item?.createdAt),
  },
  {
    key: "seK",
    fieldName: "seK",
    name: loc.columns.se,
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => getDeviceInfo(item.eventData || "")?.seK
  },
  {
    key: "nomer",
    fieldName: "nomer",
    name: loc.columns.serialN,
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => getDeviceInfo(item.eventData || "")?.nomer
  },
  {
    key: "warranty",
    fieldName: "warranty",
    name: loc.columns.warrantyHint,
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => <BasicCheckbox disabled checked={getDeviceInfo(item.eventData || "")?.warranty} />
  },
  {
    key: "vozvrBezR",
    fieldName: "vozvrBezR",
    name: loc.columns.returnWithoutRepair,
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => <BasicCheckbox disabled checked={getDeviceInfo(item.eventData || "")?.vozvrBezR} />
  },
  {
    key: "stoimRem",
    fieldName: "stoimRem",
    name: loc.columns.costOfRepairHint,
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => getDeviceInfo(item.eventData || "")?.stoimRem?.toFixed(2)
  },
  {
    key: "platRem",
    fieldName: "platRem",
    name: loc.columns.paidRepair,
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: DeviceAuditBrowseDto) => <BasicCheckbox disabled checked={getDeviceInfo(item.eventData || "")?.platRem} />
  },
];