import React, { useContext, useEffect, useState } from "react";
import { PageWrapperTitle } from "components/common/PageWrapper/Title";
import { PageWrapperContent } from "components/common/PageWrapper/Content";
import { useApi, useHttp } from "hooks";
import { PageWrapperDetails } from "components/common/PageWrapper/Details";
import styles from "./styles.module.scss";
import { Widget } from "components/common/Widget";
import { companyOptions } from "constants/options";
import { find } from "lodash-es";
import { AuthContext } from "context/authContext";
import { ExpiringLicenseDto, GetExpiredRepairPlansResponse } from "generated-sources/openapi";
import { LanguageContext } from "context/languageContext";
import { REQUESTS_RMA_SUBMITTED_PAGE_PATH_FULL, SERVICE_PATH_APPROVAL_REQUIRED_FULL, SERVICE_PATH_COST_CONFIRMED_FULL, SERVICE_PATH_DEFECTS_NOT_ENTERED_FULL, SERVICE_PATH_EXPIRED_FULL, SERVICE_PATH_NOT_A_WARRANTY_FULL, SERVICE_PATH_REPAIR_REQUIRED_FULL, SERVICE_PATH_REPLACEMENT_TO_NEW_FULL, SERVICE_PATH_REQUEST_CONFIRMATION_FULL } from "constants/paths";
import { ExpiringLicenses } from "./ExpiringLicenses";
import { DevicesWithExpiringLicenses } from "./DevicesWithExpiringLicenses";
import { CommandbarCommands } from "constants/enums";

export const DashboardPage = () => {
  const { loc } = useContext(LanguageContext);

  const defectsRequiredHttp = useHttp<number>({ showErrors: true });
  const devicesWithoutCostHttp = useHttp<number>({ showErrors: true });
  const agreedRepairCostHttp = useHttp<number>({ showErrors: true });
  const devicesCountHttp = useHttp<GetExpiredRepairPlansResponse>({ showErrors: true });
  const expiringLicensesCountHttp = useHttp<ExpiringLicenseDto[]>({ showErrors: true });
  const newRmaRequestsCountHttp = useHttp<number>({ showErrors: true });
  const qtyOfPaidRepairsCountHttp = useHttp<number>({ showErrors: true });
  const approvedNotPassedHttp = useHttp<number>({ showErrors: true });
  const requestConfirmationHttp = useHttp<number>({ showErrors: true });
  const replacementToNewHttp = useHttp<number>({ showErrors: true });
  const devicesWithExpiringLicenseHttp = useHttp<number>({ showErrors: true });

  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const { company } = useContext(AuthContext);
  const { dashboardApi } = useApi();
  const userCounterparty = find(companyOptions, { text: company });

  const [expiredCount, setExpiredCount] = useState(0);
  const [expiringLicensesCount, setExpiringLicensesCount] = useState(0);
  const [rmaRequestsCount, setRmaRequestsCount] = useState(0);
  const [qtyOfPaidRepairsCount, setQtyOfPaidRepairsCount] = useState(0);
  const [defectsNotEnteredCount, setDefectsNotEnteredCount] = useState(0);
  const [notAWarrantyDevicesCount, setNotAWarrantyDevicesCount] = useState(0);
  const [confirmRepairCostDevicesCount, setConfirmRepairCostDevicesCount] = useState(0);
  const [approvedNotPassedCount, setApprovedNotPassedCount] = useState(0);
  const [requestConfirmationCount, setRequestConfirmationCount] = useState(0);
  const [replacementToNewCount, setReplacementToNewCount] = useState(0);
  const [devicesWithExpiringLicenseCount, setDevicesWithExpiringLicenseCount] = useState(0);

  const firmId = Number(userCounterparty?.key);

  const getLicensesCount = () => {
    devicesCountHttp.request(() => dashboardApi.apiDashboardFirmIdRepairPlansPost({
      firmId
    })).then(res => setExpiredCount(res.data.count || 0));
  };

  const getExpiringLicenses = () => {
    expiringLicensesCountHttp.request(() => dashboardApi.apiDashboardFirmIdLicensesPost({
      firmId
    })).then(res => setExpiringLicensesCount(res.data.length));
  };

  const getNewRmaRequestsCount = () => {
    newRmaRequestsCountHttp.request(() => dashboardApi.apiDashboardRmaRequestsPost())
      .then(res => setRmaRequestsCount(res.data));
  };

  const getDefectsRequired = () => {
    defectsRequiredHttp.request(() => dashboardApi.apiDashboardFirmIdDefectsRequiredPost({
      firmId
    }))
      .then(res => setDefectsNotEnteredCount(res.data));
  };

  const getDevicesWithoutCost = () => {
    devicesWithoutCostHttp.request(() => dashboardApi.apiDashboardFirmIdDevicesWithoutCostPost({
      firmId
    }))
      .then(res => setNotAWarrantyDevicesCount(res.data));
  };

  const getAgreedRepairCostCount = () => {
    agreedRepairCostHttp.request(() => dashboardApi.apiDashboardFirmIdAgreedRepairCostPost({
      firmId
    }))
      .then(res => setConfirmRepairCostDevicesCount(res.data));
  };

  const getQtyOfPaidRepairs = () => {
    qtyOfPaidRepairsCountHttp.request(() => dashboardApi.apiDashboardFirmIdPaidRepairsPost({
      firmId
    })).then(res => setQtyOfPaidRepairsCount(res.data));
  };

  const getQtyOfapprovedNotPastItems = () => {
    approvedNotPassedHttp.request(() => dashboardApi.apiDashboardFirmIdSendToRepairRequiredPost({
      firmId
    })).then(res => setApprovedNotPassedCount(res.data));
  };

  const getQtyOfRequestConfirmationItems = () => {
    requestConfirmationHttp.request(() => dashboardApi.apiDashboardFirmIdDevicesRequestConfirmationPost({
      firmId
    })).then(res => setRequestConfirmationCount(res.data));
  };

  const getQtyOfReplacementToNewItems = () => {
    replacementToNewHttp.request(() => dashboardApi.apiDashboardFirmIdDevicesReplacementForNewPost({
      firmId
    })).then(res => setReplacementToNewCount(res.data));
  };

  const getQtyExpiredLicensesItems = () => {
    devicesWithExpiringLicenseHttp.request(() => dashboardApi.apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost({
      firmId
    })).then(res => setDevicesWithExpiringLicenseCount(res.data));
  };

  useEffect(() => {
    getExpiringLicenses();
    getLicensesCount();
    getNewRmaRequestsCount();
    getQtyOfPaidRepairs();
    getDefectsRequired();
    getDevicesWithoutCost();
    getAgreedRepairCostCount();
    getQtyOfapprovedNotPastItems();
    getQtyOfRequestConfirmationItems();
    getQtyOfReplacementToNewItems();
    getQtyExpiredLicensesItems();
  }, [firmId]);

  return (
    <PageWrapperContent className={styles.dashboard}>
      <PageWrapperTitle title="Dashboard" />
      <PageWrapperDetails isLoading={false} noData={false}>
        <div className={styles.widgetWrapper}>
          <Widget
            count={expiredCount}
            topHeader={loc.labels.devices}
            bottomTitle={loc.labels.expired}
            link={SERVICE_PATH_EXPIRED_FULL}
            isLoading={devicesCountHttp.isLoading}
          />
          <Widget
            topHeader={loc.labels.qtyOfPaidRepairs}
            count={qtyOfPaidRepairsCount}
            bottomTitle={loc.labels.requiresAgreement}
            link={SERVICE_PATH_APPROVAL_REQUIRED_FULL}
            isLoading={qtyOfPaidRepairsCountHttp.isLoading}
          />
          <Widget
            topHeader={loc.labels.rmaRequests}
            count={rmaRequestsCount}
            bottomTitle={loc.labels.submitted}
            link={REQUESTS_RMA_SUBMITTED_PAGE_PATH_FULL}
            isLoading={newRmaRequestsCountHttp.isLoading}
          />
          <Widget
            topHeader={loc.labels.licenses}
            count={expiringLicensesCount}
            bottomTitle={loc.labels.expireSoon}
            isLoading={expiringLicensesCountHttp.isLoading}
            onClick={() =>
              setActiveCommand(CommandbarCommands.OpenExpiringLicensesModal)
            }
          />
          <Widget
            topHeader={loc.labels.devices}
            count={defectsNotEnteredCount}
            bottomTitle={loc.legend.defectsAreMissing}
            isLoading={defectsRequiredHttp.isLoading}
            link={SERVICE_PATH_DEFECTS_NOT_ENTERED_FULL}
          />
          <Widget
            topHeader={loc.labels.devices}
            count={notAWarrantyDevicesCount}
            bottomTitle={loc.labels.nowAWarrantyRepairCost}
            isLoading={devicesWithoutCostHttp.isLoading}
            link={SERVICE_PATH_NOT_A_WARRANTY_FULL}
          />
          <Widget
            topHeader={loc.labels.costOfRepairConfirmed}
            count={confirmRepairCostDevicesCount}
            bottomTitle={loc.labels.toPlan}
            isLoading={agreedRepairCostHttp.isLoading}
            link={SERVICE_PATH_COST_CONFIRMED_FULL}
          />
          <Widget
            topHeader={loc.labels.devices}
            count={approvedNotPassedCount}
            bottomTitle={loc.labels.approvedNotPassedWidget}
            isLoading={approvedNotPassedHttp.isLoading}
            link={SERVICE_PATH_REPAIR_REQUIRED_FULL}
          />
          <Widget
            topHeader={loc.labels.forDevices}
            count={requestConfirmationCount}
            bottomTitle={loc.labels.requestClarifications?.toLowerCase()}
            isLoading={requestConfirmationHttp.isLoading}
            link={SERVICE_PATH_REQUEST_CONFIRMATION_FULL}
          />
          <Widget
            topHeader={loc.labels.devices}
            count={replacementToNewCount}
            bottomTitle={loc.labels.replacementToNew}
            isLoading={replacementToNewHttp.isLoading}
            link={SERVICE_PATH_REPLACEMENT_TO_NEW_FULL}
          />
          <Widget
            topHeader={loc.labels.devices}
            count={devicesWithExpiringLicenseCount}
            bottomTitle={loc.labels.withExpiringLicense}
            isLoading={devicesWithExpiringLicenseHttp.isLoading}
            onClick={() =>
              setActiveCommand(CommandbarCommands.OpenDevicesWithExpiringLicensesModal)
            }
          />
        </div>
      </PageWrapperDetails>
      <>
        {activeCommand === CommandbarCommands.OpenExpiringLicensesModal && (
          <ExpiringLicenses firm={firmId} onDismiss={handleModalDismiss} />
        )}
        {activeCommand === CommandbarCommands.OpenDevicesWithExpiringLicensesModal && (
          <DevicesWithExpiringLicenses firm={firmId} onDismiss={handleModalDismiss} />
        )}
      </>
    </PageWrapperContent>
  );
};
