export enum Sorting {
  ASC = "asc",
  DESC = "desc",
}
export enum Notification {
  SUCCESS = "success",
  ERROR = "danger",
  INFO = "info",
  DEFAULT = "default",
  WARNING = "warning",
}
export enum CommandbarCommands {
  Display,
  Add,
  AddGroup,
  Edit,
  EditNote,
  Select,
  Devices,
  Appoint,
  Decline,
  Accept,
  Delete,
  Create,
  Excel,
  Approve,
  Check,
  Cancel,
  None,
  Upload,
  Rating,
  Print,
  Confirm,
  OpenIncomingInvoicePanel,
  OpenOutgoingInvoicePanel,
  EditDocData,
  EditDeclaration,
  EditDate,
  Repair,
  Service,
  ProformaInvoice,
  Send,
  AccompDoc,
  PackingList,
  DefectsList,
  Return,
  TZ,
  RepairCost,
  ToSales,
  CountryOfOrigin,
  Details,
  Serial,
  SerialBattery,
  TechnicalConclusion,
  History,
  Correct,
  CorrectBattery,
  LoadDefects,
  Calculation,
  LoadData,
  ReplacedList,
  Panel,
  OpenIncomingInvoiceDevicesPanel,
  OpenOutgoingInvoiceDevicesPanel,
  ReloadDefects,
  Worklist,
  AddBattery,
  ReloadNotification,
  DeleteBattery,
  EditRepairArea,
  EditRepairDate,
  RequestAnAgreement,
  PrintTZ,
  ExcelTZ,
  ScheduleRepairs,
  EditComplaints,
  EditRepairs,
  Reject,
  Document,
  PriceSelection,
  Equipment,
  Barcode,
  WriteOff,
  GeneralReport,
  Preview,
  ExcelPreview,
  ReturnLevel,
  AllData,
  ByCompany,
  DevicesOfTheCurrentYear,
  RoleManagement,
  ConfigureNotifications,
  EmployeeBadge,
  AccompanyingCard,
  ReplaceFromRa,
  Download,
  OpenInvoice,
  OpenExpiringLicensesModal,
  AddToReport,
  Clear,
  MonthReport,
  Save,
  ConfirmCheck,
  Warning,
  AddedList,
  AddRma,
  AddRmaUpgrade,
  Reports,
  EquipmentExcel,
  ConfirmRma,
  UsedSpareParts,
  SparePartsReceipt,
  SparePartsSpending,
  SparePartsExcel,
  CancelApproval,
  SparePartsSpendingTotal,
  Package,
  ReturnWithoutRepair,
  OpenDevicesWithExpiringLicensesModal
}
export enum AppLanguage {
  English = "en",
  Russian = "ru",
}
export enum Permissions {
  FullControl = "FullControl", // spec
  BrowseOrganizations = "BrowseOrganizations", // switch
  ManageWarehouse = "ManageWarehouse", // skl
  ManageOtk = "ManageOtk", // otk
  ManageRepairPlans = "ManageRepairPlans", // PlanRem
  ManageRepairs = "ManageRepairs", // Rem
  TransferDocumentsToSalesModule = "TransferDocumentsToSalesModule", // Sbyt
  ManageDefects = "ManageDefects", // Defect
  SendDevicesToDistributor = "SendDevicesToDistributor", // Otpr
}

export enum RmaStatus {
  Open = "00",
  PartiallyShipped = "92",
  Received = "01",
  Shipped = "02",
  Diagnostics = "11",
  Cancelled = "99",
  Repair = "21",
  Prepairing = "98",
}

export enum Result {
  Yes = "1",
  No = "0",
}

export enum DocumentCode {
  Outgoing = "02",
  Incoming = "01",
  IncomingInvoiceInternal = "41",
  OutgoingInvoiceInternal = "42",
  FakeInvoiceInternal = "43",
  RMA = "99",
}

export enum PanelTab {
  Main = "Main",
  Other = "Other",
}
export enum InvoiceStatus {
  Processed = "01",
  NotProcessed = "00",
}

export enum Companies {
  Pulsar = "Pulsar",
  Yukon = "Yukon",
  Polaris = "Polaris",
  OPYU = "OPYU",
}

export enum SignalRNotificationType {
  MaintenanceToRepairArea = "Maintenance.ToRepairArea",
  MaintenanceReplaceToNew = "Maintenance.ReplaceToNew",
  MaintenanceReturnToReplacementArea = "Maintenance.ReturnToReplacementArea",
  InvoiceCreate = "Invoice.Create",
  RmaCreate = "Rma.Create",
  RmaCreateFromRequest = "Rma.CreateFromRequest",
  RmaRequestCreate = "RmaRequest.Create",
  DeviceLock = "Device.Lock",
  DeviceUnlock = "Device.Unlock",
  RmaCreateUpgrade = "Rma.CreateUpgrade",
  SparePartsAdd = "SpareParts.Add"
}

export enum RepairPlanStatus {
  NotReadyForPlanning = -1,
  ReadyForPlanning = 0,
  UnderConsideration = 1,
  NotAgreed = 2,
  Agreed = 3,
  Completed = 4,
  Overdue = 5
}

export enum DeviceStatus {
  Default = "",
  Repaired = "0",
  Damaged = "1",
  AgreedToPay = "AgreedToPay",
  NotAgreedToPay = "NotAgreedToPay"
}

export enum Warranty {
  Default = "",
  Warranty = "Warranty",
  NoWarranty = "NoWarranty"
}

export enum AuditEventType {
  WarrantyChanged = "WarrantyChanged",
  ReturnWithoutRepairChanged = "ReturnWithoutRepairChanged",
  RepairCostChanged = "RepairCostChanged",
  PaymentConsentChanged = "PaymentConsentChanged",
  SeChanged = "SeChanged",
  SerialChanged = "SerialChanged",
  DeviceAdded = "DeviceAdded",
  DeviceAgreed = "DeviceAgreed",
  DeviceDisagreed = "DeviceDisagreed",
  RepairAreaChanged = "RepairAreaChanged",
  RepairDateChanged = "RepairDateChanged",
  SecondaryControlCompletedChanged = "SecondaryControlCompletedChanged",
  RepairCostAdded = "RepairCostAdded",
  RepairCostRemoved = "RepairCostRemoved",
  PreliminaryCostChanged = "PreliminaryCostChanged",
  RepeatedRepairConfirmedChanged = "RepeatedRepairConfirmedChanged",
  Packed = "Packed",
  Approved = "Approved",
  ApprovalCancelled = "ApprovalCancelled",
  PackagingConfirmed = "PackagingConfirmed",
  WaitChanged = "WaitChanged",
  CompleteToSalesStateChanged = "CompleteToSalesStateChanged",
  RepairToSalesStateChanged = "RepairToSalesStateChanged"
};

export enum Months {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
};