export const BLUE_COLOR = "#cce5ff";
export const BLACK_COLOR = "#000";
export const WHITE_COLOR = "#fff";
export const YELLOW_COLOR = "#fdef6e";
export const RED_COLOR = "#f44336";
export const GREEN_COLOR = "#64dd17";
export const PURPLE_COLOR = "#b388ff";
export const ORANGE_COLOR = "#ff9800";
export const PINK_COLOR = "#ff4383";
export const LIGHT_BLUE_COLOR = "#83ffff";
export const GREY_COLOR = "#c6c6c6";
export const LIGHT_GREY_COLOR = "#eee";
export const LIGHT_PINK_COLOR = "#ff00c8";
export const LIGHT_PURPLE_COLOR = "#d8c2ff";
export const LIGHT_GREEN_COLOR = "#b6f78d";
export const DARK_GREEN_COLOR = "#2d630a";
export const NAVY_BLUE_COLOR = "#021274";
export const SKY_BLUE_COLOR = "#03A9F4";
export const PRIMARY_COLOR = "#009688";
export const DARK_PURPLE_COLOR = "#301934";
export const MAGENTA_COLOR = "#E81259";
