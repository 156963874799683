import { FontIcon, Icon, TextField } from "@fluentui/react";
import { isUndefined } from "lodash-es";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IBarcodeInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  active?: boolean;
  onReset?: () => void;
  onEnterPressed: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

export const BarcodeInput = (props: IBarcodeInputProps) => {
  const onRenderSuffix = () => <FontIcon iconName="ChromeClose" onClick={() => {
    props.onReset && props.onReset();
    props.onChange("");
  }} />;
  return (
    <TextField
      value={props.value}
      className={classNames(styles.barcodeInput, { [styles.active]: props.active })}
      onRenderPrefix={() => <Icon iconName="GenericScan" />}
      placeholder={props.placeholder}
      onChange={(_, value?: string) => {
        if (!isUndefined(value)) props.onChange(value);
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          props.onEnterPressed(props.value);
          props.onChange("");
        }
      }}
      disabled={props.disabled}
      onRenderSuffix={props.active ? onRenderSuffix : undefined}
      autoFocus={props.autoFocus}
    />
  );
};
