import { TooltipHost } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import { DeviceExpiringLicenseDto } from "generated-sources/openapi";
import loc from "localization";
import { transformDateToString } from "utils";

export const getColumns = (language: AppLanguage) => [

  {
    key: "name",
    name: loc.columns.device,
    fieldName: "name",
    isResizable: true,
    minWidth: 160,
    maxWidth: 175,
    onRender: (item: DeviceExpiringLicenseDto) => (
      <TooltipHost content={language === AppLanguage.English ? item.nameEn : item.nameRu}>
        {language === AppLanguage.English ? item.nameEn : item.nameRu}
      </TooltipHost>
    )
  },
  {
    key: "licenseExpiring",
    name: loc.columns.licenseExpires,
    fieldName: "licenseExpiring",
    isResizable: true,
    minWidth: 120,
    maxWidth: 140,
    onRender: (item: DeviceExpiringLicenseDto) => transformDateToString(item.licenseExpiring)
  },
  {
    key: "counterparty",
    name: loc.columns.distributor,
    minWidth: 100,
    maxWidth: 120,
    fieldName: "counterparty",
    isResizable: true,
  },
  {
    key: "rmaNumber",
    name: loc.columns.rmaNumber,
    minWidth: 85,
    maxWidth: 85,
    fieldName: "rmaNumber",
  },
  {
    key: "receiptDate",
    name: loc.columns.arrivalDate,
    fieldName: "receiptDate",
    isResizable: true,
    minWidth: 100,
    maxWidth: 120,
    onRender: (item: DeviceExpiringLicenseDto) => transformDateToString(item.receiptDate)
  },
];